<template>
  <div>
    <el-dialog :title="dialogTitle" :visible="dialogVisible_sgForm" :close-on-click-modal="false" width="900px"
               custom-class="cus_dialog cus_dialog_print"
               @close="closeDialog">

      <div style="text-align: right; position: absolute; top:2px; right: 50px;">
        <el-button v-print="'#printMe'" icon="el-icon-printer" type="warning">此打印组件已作废</el-button>
      </div>
      <div style="width: 800px; height: 800px; border: 1px solid #ccc; margin: 0 auto; overflow-y: scroll">
        <div id="printMe" style=" width:740px; margin: 0 auto; height:1050px; overflow:hidden;">
          <table class="printtable" style="margin-top: 20px;">
            <tr><th colspan="7">{{EditItem.sg_num}}申购单</th></tr>
            <tr>
              <td>申购人</td>
              <td>{{EditItem.username||'-'}}</td>
              <td>所在部门</td>
              <td>{{EditItem.dept_name||'-'}}</td>
              <td>申购时间</td>
              <td colspan="2">{{EditItem.cdate||'-'}}</td>
            </tr>
            <tr>
              <td>购置方式</td>
              <td>{{EditItem.buy_type||'-'}}</td>
              <td>预估总价(元)</td>
              <td colspan="4">
                <span>￥{{EditItem.howmuch||'-'}}</span>&nbsp;|&nbsp;<span>{{upperCaseMoney(EditItem.howmuch)||'-'}}</span></td>
            </tr>
            <tr>
              <td>购买原因</td>
              <td colspan="6">{{EditItem.remark||'-'}}</td>
            </tr>
            <tr>
              <td>附件</td>
              <td colspan="6">
                <div class="flexStart">
                  <div v-for="(item,index) in EditItem.files">
                    <img :src="item.src"
                         style="width: 200px;"/>
                  </div>
                </div>
             </td>
            </tr>

            <tr><th colspan="7">购买清单</th></tr>
            <tr>
              <td style="width:14%;">耗材名称</td>
              <td style="width:14%;">耗材分类</td>
              <td style="width:14%;">耗材品牌</td>
              <td style="width:14%;">采购单价(元)</td>
              <td style="width:14%;">采购数量</td>
              <td style="width:14%;">总价(元)</td>
              <td style="width:16%;">备注</td>
            </tr>
            <tr v-for="(item, index) in EditItem.hc_list">
              <td style="width:14%;">{{ item.title||'-' }}</td>
              <td style="width:14%;">{{ item.typename1||'-' }}</td>
              <td style="width:14%;">{{ item.pinpai||'-' }}</td>
              <td style="width:14%;">{{ item.cgJine||'-' }}</td>
              <td style="width:14%;">{{ item.cgNum||'-' }}</td>
              <td style="width:14%;">{{ item.cgTotal||'-' }}</td>
              <td style="width:16%;">{{ item.user_remark||'-' }}</td>
            </tr>
            <tr><th colspan="7">审核流程</th></tr>
            <tr v-for="(item,index) in EditItem.process_json">
              <td colspan="2">{{item.sp_users&&item.sp_users.length>0?item.sp_users[0].username:'-'}}审核</td>
              <td colspan="2">{{item.sp_users&&item.sp_users.length>0?item.sp_users[0].remark:'-'}}</td>
              <td colspan="3">
                <div class="flexAround">
                  <img :src="item.sp_users[0].handsign" v-if="item.sp_users&&item.sp_users.length>0" style="width: 100px;"/>
                  <div v-if="!item.sp_users||item.sp_users.length==0||!item.sp_users[0].handsign" style="width: 100px;">-</div>
                  <div class="">{{item.sp_users&&item.sp_users.length>0?item.sp_users[0].sptime:'-'}}</div>
                </div>
              </td>
            </tr>

          </table>


        </div>

      </div>


    </el-dialog>
  </div>
</template>

<script>
export default {

  props: ['dialogTitle', 'dialogVisible_sgForm', 'EditItem'],
  data() {
    return {

    }
  },
  mounted() {
    console.log(858585,this.EditItem)
    let d = new Date();

    this.date = d.getFullYear() + "年" + (d.getMonth() + 1) + "月" + d.getDate() + "日"
  },
  methods: {
    upperCaseMoney(money) {
      // 汉字的数字
      var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
      //基本单位
      var cnIntRadice = new Array('', '拾', '佰', '仟');
      //对应整数部分扩展单位
      var cnIntUnits = new Array('', '万', '亿', '兆');
      //对应⼩数部分单位
      var cnDecUnits = new Array('⾓', '分', '毫', '厘');
      //整数⾦额时后⾯跟的字符
      var cnInteger = '整';
      //整型完以后的单位
      var cnIntLast = '元';
      //最⼤处理的数字
      var maxNum = 999999999999999.9999;
      //⾦额整数部分
      var integerNum;
      //⾦额⼩数部分
      var decimalNum;
      //输出的中⽂⾦额字符串
      var chineseStr = '';
      //分离⾦额后⽤的数组，预定义
      var parts;
      if (money == '') {
        return '';
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最⼤处理数字
        return '';
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf('.') == -1) {
        integerNum = money;
        decimalNum = '';
      } else {
        parts = money.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }

      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == '0') {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //⼩数部分
      if (decimalNum != '') {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1);
          if (n != '0') {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }


      }
      if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum == '') {
        chineseStr += cnInteger;
      }
      return chineseStr;


    },
    closeDialog() {
      this.$$parent(this,'dialogVisible_sgForm',false)
    }
  }
}
</script>

<style scoped="scoped">
.uline {
  text-decoration: underline;
}

.printtable {
  width: 100%;
  border-right: 1px solid #804040;

  border-bottom: 1px solid #804040;

  border-collapse: collapse;
}

.printtable td, .printtable th{
  border-left: 1px solid #804040;

  border-top: 1px solid #804040;
  padding: 20px 10px;
  text-align: center;
}
</style>
